import { useEffect, useState } from 'react';
import { FiCalendar } from 'react-icons/fi';
import { FormControl, FormErrorMessage, FormLabel, Input, InputProps } from '@chakra-ui/react';

import styles from './input-core.module.css';

interface InputCoreProps extends Omit<InputProps, 'onChange' | 'value'> {
    label?: string;
    value?: string;
    required?: boolean;
    showError?: boolean;
    error?: string;
    setError?: (value: string) => void;
    onChange?: (value: string) => void;
}

export const InputCore = ({
    label,
    placeholder,
    size,
    value,
    type,
    required,
    onChange,
    mb,
    showError,
    error,
    setError,
    ...props
}: InputCoreProps) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange: React.ComponentProps<'input'>['onChange'] = (event) => {
        setInputValue(event.target.value);
        onChange?.(event.target.value);
        setError?.('');
    };

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    return (
        <FormControl isRequired={required} isInvalid={(!inputValue && showError) || !!error} mb={mb}>
            <FormLabel fontWeight={400} fontSize={'14px'} lineHeight={'20px'} mb='4px' color='rgba(79, 79, 79, 1)'>
                {label}
            </FormLabel>
            <div className={styles['input-container']}>
                <Input
                    {...props}
                    type={type}
                    max={getCurrentDate()}
                    size={size}
                    color='rgba(79, 79, 79, 1)'
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                    background={'rgba(251, 251, 251, 1)'}
                    borderColor='rgba(236, 236, 236, 1)'
                    className={`${props.className} ${styles.input}`}
                    _hover={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                    _active={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                    _focusVisible={{
                        borderColor: 'rgba(3, 77, 33, 1)',
                    }}
                />
                {type === 'date' ? <FiCalendar className={styles['input-icon']} /> : null}
                {!inputValue && showError ? <FormErrorMessage>{error ?? 'Заполните это поле'}</FormErrorMessage> : null}
                {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
            </div>
        </FormControl>
    );
};
